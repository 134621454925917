<template>
  <div>
    <div v-if="isshowgrid">
      <va-card title="Room List">
        <div class="row">
          <div class="flex md6 mt-2">
            <va-input class="va-input-search-container" :value="term" placeholder="search" @input="search" removable>
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex md6 mt-2 text-right">
            <va-button icon="fa fa-plus" class="my-0" @click.prevent="add()">
              Create
            </va-button>
          </div>
        </div>
        <va-data-table :fields="fields" :data="filteredData" :per-page="parseInt(perPage)" style="max-height: 90vh;">
          <template slot="actions" slot-scope="props">
            <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0">
            </va-button>
          </template>
          <template slot="action" slot-scope="props">
            <va-button flat small color="red" icon="fa fa-remove" @click="remove(props.rowData)" class="ma-0">
            </va-button>
          </template>
        </va-data-table>
        <va-modal v-model="showRemoveModal" title="Delete Room" size='small' :message="msg" okText="Confirm"
          cancelText="Cancel" @ok="deleteRoomRes(entity)" @cancel="cancel()">
        </va-modal>
      </va-card>
    </div>
    <div v-if="isshowForm">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Guest Name</span>
            <va-input 
            	v-model.trim="guest_name" 
            	type="text" 
            	v-if="isCreate || isUpdate" 
            	placeholder="Enter guest name"
              :error="!!GuestNameErrors.length" 
              :error-messages="GuestNameErrors" 
           	/>
           	<span class="va-form-label va-form-required-label">Room Number</span>
            <va-select
              v-if="isCreate || isUpdate"
              placeholder="Select room number"
              v-model="room_no"
              textBy="roomNo"
              searchable
              :options="roomNoArr"
              :error="!!RoomNoErrors.length"
              :error-messages="RoomNoErrors"
            />
           	<span class="va-form-label va-form-required-label">Mobile Number</span>
            <va-input 
            	v-model.trim="mobile" 
            	type="text" 
              maxlength=10
              pattern="[6-9]{1}[0-9]{9}"
            	v-if="isCreate || isUpdate" 
            	placeholder="Enter mobile number"
              :error="!!MobileErrors.length" 
              :error-messages="MobileErrors" 
           	/>
            <div class="row flex md12">
              <label
                class="date_picker_label flex md4 va-form-label va-form-required-label">Check-In Time</label>
              <label
                class="date_picker_label flex md4 ml-4 va-form-label va-form-required-label">Check-Out Time</label>
            </div>
            <div class="flex md12 row" style="margin-top: -15px;">
              <datepicker
                inputClass="input"
                class="flex md4"
                v-model="check_in_time"
                format="MMM dd yyyy"
                placeholder="MM-DD-YYYY"
                :disabledDates="disabledDate"
              />
              <datepicker
                inputClass="input"
                class="flex md4 ml-4"
                v-model="check_out_time"
                format="MMM dd yyyy"
                placeholder="MM-DD-YYYY"
                :disabledDates="disabledDate"
              />
            </div>
            <div style="margin-top: 270px;">
              <div class="d-flex justify--end mt-3">
                <va-button type="submit" class="my-0 va-button-normal-cancel" @click="list()">Cancel</va-button>
                <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createRoomRes()">Create</va-button>
                <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateRoomRes()">Update</va-button>
              </div>              
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>

  import Vue from 'vue'
  import vueResource from 'vue-resource'
  import { search } from '../../i18n/search.js'
  import config from '../../i18n/en.json'
  import Datepicker from 'vuejs-datepicker'
  Vue.use(vueResource)

  export default {
    name: 'room_reservation',
    components: { Datepicker },
    beforeCreate() {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/room_reservation').then(response => {
        loader.hide()
        this.room_res_list = response.body
        this.isshowgrid = true
        this.$http.get(config.menu.host + '/room').then(resp => {
          loader.hide();
          var index = 0
          this.roomNoArr = resp.body
          this.roomNoArr.map(function(room_arg){
            room_arg.id = index++
            room_arg.roomNo = room_arg.room_no
          })
        })
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      });
    },
    data() {
      return {
        term: null,
        perPage: '10',
        perPageOptions: ['4', '6', '10', '20'],
        room_res_list: [],
        guest_name: '',
        GuestNameErrors: [],
        room_no: '',
        roomNoArr: [],
        RoomNoErrors: [],
        mobile: '',
        MobileErrors: [],
        check_in_time: new Date(),
        check_out_time: new Date(),
        disabledDate: {
      		to: null,
      	},
        isshowgrid: true,
        isshowForm: false,
        isCreate: false,
        isUpdate: false,
        showRemoveModal: false,
        msg: ''
      }
    },
    computed: {
      formReady() {
        return !this.GuestNameErrors.length && 
               !this.RoomNoErrors.length && 
        			 !this.MobileErrors.length
      },
      fields() {
        return [{
          name: 'guest_name',
          title: 'Guest Name'
        },
        {
          name: 'room_no',
          title: 'Room Number'
        },
        {
        	name: 'mobile_number',
        	title: 'Mobile'
        },
        {
          name: 'checkin_time',
          title: 'Check-In'
        },
        {
          name: 'checkout_time',
          title: 'Check-Out'
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        },
        {
          name: '__slot:action',
          dataClass: 'text-right',
        }]
      },
      filteredData() {
        return search(this.term, this.room_res_list)
      },
    },
    methods: {
      getRoomRes() {
        const loader = Vue.$loading.show({ width: 40, height: 40 });
        this.$http.get(config.menu.host + '/room_reservation').then(response => {
          loader.hide();
          this.room_res_list = response.body;
          this.isshowgrid = true;
        }, error => {
          loader.hide();
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          };
        });
      },
      createRoomRes() {
        this.GuestNameErrors = this.guest_name ? [] : ['Guest Name is required'];
        this.RoomNoErrors = this.room_no ? [] : ['Room Number is required'];
        this.MobileErrors = this.mobile ? [] : ['Mobile is required'];
        if(this.check_in_time>this.check_out_time){
          return Vue.notify({ text: 'Invalid Date', type: 'error'})
        }
        if (!this.formReady) { return };
        if (this.check_in_time == '' || this.check_out_time == '') {  
          return Vue.notify({ text: 'Please fill the form correctly', type: 'error'})
        }

				var payload = {
					guest_name    : this.guest_name,
					room_no       : this.room_no.room_no,
					mobile_number : this.mobile,
          checkin_time  : this.check_in_time,
          checkout_time : this.check_out_time
				} 
        const loader = Vue.$loading.show({ width: 40, height: 40 });
        this.$http.post(config.menu.host + '/room_reservation', payload).then(responseData => {
          loader.hide();
          Vue.notify({ text: responseData.body, type: 'success' });
          this.list();
        }, err => {
          loader.hide();
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          }else{
            Vue.notify({ text: 'Selected Room is unavailable', type: 'error'})
          }
        });
      },
      updateRoomRes() {
        this.GuestNameErrors = this.guest_name ? [] : ['Guest Name is required'];
        this.RoomNoErrors = this.room_no ? [] : ['Room Number is required'];
        this.MobileErrors = this.mobile ? [] : ['Mobile is required'];
        if(new Date(this.check_in_time)>new Date(this.check_out_time)){
          return Vue.notify({ text: 'Invalid Date', type: 'error'})
        }
        if (!this.formReady) { return };
        if (this.check_in_time == '' || this.check_out_time == '') { 
          return Vue.notify({ text: 'Please fill the form correctly', type: 'error'})
        }

        var payload = {
          guest_name    : this.guest_name,
          room_no: (this.room_no.room_no) ? this.room_no.room_no : this.room_no,
          mobile_number : this.mobile,
          checkin_time  : this.check_in_time,
          checkout_time : this.check_out_time
        } 

        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.put(config.menu.host + '/room_reservation/' + this.room_reservation_id, payload).then(responseData => {
          loader.hide();
          Vue.notify({ text: responseData.body, type: 'success' });
          this.list();
        }, err => {
          loader.hide();
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          }else{
            Vue.notify({ text: 'Room are unavailable on selected date', type: 'error'})
          }
        });
      },
      edit(row) {
        this.disabledDate = {to: null};
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        this.disabledDate.to = yesterday;
        this.title = 'Update Room Reservation';
        this.room_reservation_id = row.room_reservation_id;
        this.guest_name = row.guest_name;
        this.room_no = row.room_no;
        this.mobile = row.mobile_number;
        this.check_in_time = row.checkin_time;
        this.check_out_time = row.checkout_time;
        this.isshowgrid = false;
        this.isshowForm = true;
        this.isCreate = false;
        this.isUpdate = true;
      },
      remove(row) {
        this.msg = 'Are you sure to delete the room ' + row.room_no + ' ?';
        this.entity = row;
        this.showRemoveModal = true;
      },
      cancel() {
        this.showRemoveModal = false;
      },
      deleteRoomRes(data) {
        this.$http.delete(config.menu.host + '/room_reservation/' + data.room_reservation_id).then(resp => {
          Vue.notify({ text: resp.body, type: 'success' });
          this.list();
        }, err => {
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          };
        });
      },
      add() {
        this.disabledDate = {to: null};
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        this.disabledDate.to = yesterday;
        this.title = 'Create Room Reservation';
        this.guest_name = '';
        this.GuestNameErrors = [];
        this.room_no = '';
        this.RoomNoErrors = [];
        this.mobile = '';
        this.MobileErrors = [];
        this.check_in_time = '';
        this.check_out_time = ''; 
        this.isshowForm = true;
        this.isshowgrid = false;
        this.isUpdate = false;
        this.isCreate = true;
      },
      list() {
        this.getRoomRes();
        this.isshowgrid = true;
        this.isshowForm = false;
      },
      search: function (term) {
        this.term = term;
      },
    },
  }
</script>

<style>
  .input {
    width: 100%;
    height: 35px;
    cursor: default;
    text-align: -moz-center;
  }
</style>
